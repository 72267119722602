import * as prismicH from '@prismicio/helpers';
import { pipe } from 'fp-ts/function';
import { getConfigValue } from '@shared/utils/config';

import * as O from 'fp-ts/Option';

function getFrontUrl(path: string): string {
  return pipe(
    getConfigValue('VITE_FRONT_URL'),
    O.chain(url => O.tryCatch(() => new URL(path, url).toString())),
    O.getOrElse(() => '/'),
  );
}

export const defaultLinkResolver: prismicH.LinkResolverFunction = doc => {
  switch (doc.type) {
    case 'home':
      return getFrontUrl('/');
    case 'visit_landing':
      return getFrontUrl(`/${doc.uid}`);
    case 'visit_app':
      return `/${doc.uid}`;
    case 'tag_app':
      return `/resolver/tags/${doc.uid}`;
    case 'gallery_app':
      return `/resolver/galleries/${doc.uid}`;
    default:
      return '/';
  }
};
