import { css } from 'styled-components';

import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';

export function renderRatio(ratio: number, isFill?: boolean) {
  const ratioValue = pipe(
    O.fromNullable(ratio),
    O.filter(r => r > 0),
    O.fold(
      () => 100,
      r => 100 / r,
    ),
  );

  return css`
    position: relative;
    padding-bottom: ${ratioValue}%;
    overflow: hidden;

    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      object-fit: ${isFill ? 'contain' : 'cover'};
      width: 100%;
      height: 100%;
    }

    > picture {
      height: 100%;
      width: 100%;

      > img,
      > source {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        object-fit: ${isFill ? 'contain' : 'cover'};
        width: 100%;
        height: 100%;
      }
    }
  `;
}
