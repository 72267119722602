import styled from 'styled-components';
import { colors, fontSizes, fontWeights, renderRatio } from '@styles/utils';

export const GalleryContainer = styled.div``;

export const GalleryTitle = styled.h1`
  padding: 20px;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
  color: ${colors.text};
  text-align: center;
  text-transform: uppercase;
`;

export const GalleryContent = styled.div``;

export const GalleryImageContainer = styled.div`
  position: relative;

  > div:first-child {
    ${renderRatio(16 / 9)};
  }
`;

export const GalleryImageLegend = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  padding: 6px 20px;
  background: rgba(0, 0, 0, 0.55);
  text-align: center;
  text-transform: uppercase;
  font-size: ${fontSizes.medium};
  color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
