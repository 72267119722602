import React, { FC } from 'react';
import { HttpError, HttpStatusCode } from '@core/http';

import * as Styled from './FullError.styles';
import { useTranslation } from 'react-i18next';

interface FullErrorProps {
  error: HttpError;
}

const FullError: FC<FullErrorProps> = ({ error }) => {
  const { t } = useTranslation();

  return (
    <Styled.FullErrorContainer>
      <Styled.FullErrorTitle>
        {t('error.label')} {error.status}
      </Styled.FullErrorTitle>
      <Styled.FullErrorDescription>
        {HttpStatusCode.NOT_FOUND === error.status ? t('error.not-found') : t('error.technical')}
      </Styled.FullErrorDescription>
    </Styled.FullErrorContainer>
  );
};

export default FullError;
