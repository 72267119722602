import { FC, useEffect } from 'react';

import ReactGA from 'react-ga4';
import { getConfigValue } from '@shared/utils/config';

import * as O from 'fp-ts/Option';
import { CookieConsentType, useCookieConsentValueByType } from '@innovantic/cookie-consent';
import { useLocation } from 'react-router-dom';

const AnalyticsTracker: FC = () => {
  const location = useLocation();

  const isStatisticAllowed = useCookieConsentValueByType(CookieConsentType.Statistic);

  useEffect(() => {
    const gaKey = O.toNullable(getConfigValue('VITE_GA_KEY'));

    if (isStatisticAllowed && gaKey !== null) {
      if (!ReactGA.isInitialized) {
        ReactGA.initialize(gaKey);
        ReactGA.send('pageview');
      }
    } else if (!isStatisticAllowed && ReactGA.isInitialized) {
      window.location.reload();
    }
  }, [isStatisticAllowed]);

  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send('pageview');
    }
  }, [location.pathname]);

  return null;
};

export default AnalyticsTracker;
