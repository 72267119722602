import styled from 'styled-components';
import Image from '@core/prismic/components/Image';
import { renderRatio } from '@styles/utils';

export const GallerySliceContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

export const GallerySliceImage = styled(Image)`
  ${renderRatio(3 / 2)};
`;
