import React, { FC } from 'react';
import { PrismicVisitContent } from '@modules/visits/model';

import * as Styled from './HomeHeader.styles';
import Image from '@core/prismic/components/Image';
import { renderNullable, renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as prismicH from '@prismicio/helpers';

interface HomeHeaderProps {
  visit: PrismicVisitContent;
}

const HomeHeader: FC<HomeHeaderProps> = ({ visit }) => {
  return (
    <div>
      <Styled.HomeHeaderTopContainer>
        <Styled.HomeHeaderTopImageWrapper>
          <Image image={visit.logo} width={105} height={105} imgixParams={{ fit: 'fill', fill: 'solid' }} />
        </Styled.HomeHeaderTopImageWrapper>

        <Styled.HomeHeaderTitle field={visit.title} />
      </Styled.HomeHeaderTopContainer>

      <Styled.HomeHeaderBottomContainer>
        <Styled.HomeHeaderMask />

        <Styled.HomeHeaderImage image={visit.header_image} sizes={{ default: '100vw' }} intrinsic />

        <Styled.HomeHeaderBottomContent>
          {renderOptional(NEA.fromArray(visit.header_intro ?? []), intro => (
            <Styled.HomeHeaderIntroContainer>
              {intro.map((item, i) => (
                <li key={i}>
                  <p>{prismicH.asText(item.header_intro_title)}</p>
                  <p>{item.header_intro_text}</p>
                </li>
              ))}
            </Styled.HomeHeaderIntroContainer>
          ))}

          {renderNullable(visit.header_audio_player, player => (
            <Styled.HomeHeaderPlayer dangerouslySetInnerHTML={{ __html: player }} />
          ))}
        </Styled.HomeHeaderBottomContent>
      </Styled.HomeHeaderBottomContainer>
    </div>
  );
};

export default HomeHeader;
