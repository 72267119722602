import * as O from 'fp-ts/Option';
import { constNull, pipe } from 'fp-ts/function';
import { ReactNode } from 'react';
import * as prismicH from '@prismicio/helpers';

export function filterPrismicElement<T>(element: T | undefined | null): O.Option<T> {
  return pipe(
    O.fromNullable(element),
    O.filter(el => {
      if (Array.isArray(el)) {
        return el.length > 0 && prismicH.asText(el as any) !== '';
      } else if (typeof el === 'string') {
        return el !== '';
      } else if (typeof el === 'object') {
        // link_type -> fix PrismicLink
        return (
          Object.keys(el).length > 0 &&
          (el as any).link_type !== 'Any' &&
          ((el as any).link_type !== 'Media' || (el as any).url != null)
        );
      } else {
        return true;
      }
    }),
  );
}

export function renderPrismicElement<T, R extends ReactNode>(
  element: T | undefined | null,
  onSome: (element: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return pipe(filterPrismicElement(element), O.fold(onNone, onSome));
}
