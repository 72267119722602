import { pipe } from 'fp-ts/function';
import theme from '@styles/theme';
import * as R from 'fp-ts/Record';
import * as String from 'fp-ts/string';
import { DefaultTheme, FontSizes, FontWeights, ThemeProps } from 'styled-components';

export const fontWeights = pipe(
  theme.font.weights,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<keyof FontWeights, (props: ThemeProps<DefaultTheme>) => number>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.weights[key],
    }),
  ),
);

export const fontSizes = pipe(
  theme.font.sizes,
  R.reduceWithIndex(String.Ord)(
    {} as Readonly<Record<keyof FontSizes, (props: ThemeProps<DefaultTheme>) => number>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ThemeProps<DefaultTheme>) => props.theme.font.sizes[key],
    }),
  ),
);
