import React, { FC } from 'react';
import { PrismicFileSlice } from '@modules/visits/model';
import SliceLayout from '@modules/visits/components/tag/slice/common/SliceLayout';

import * as Styled from './FileSlice.styles';

import downloadIcon from '@assets/icons/tag/download.png';

interface FileSliceProps {
  slice: PrismicFileSlice;
}

const FileSlice: FC<FileSliceProps> = ({ slice }) => {
  return (
    <SliceLayout title={slice.primary.app_file_title}>
      <Styled.FileSliceContainer>
        {slice.items.map((item, i) => (
          <a key={i} href={item.app_file?.url} target="_blank" rel="noopener noreferrer">
            <img src={downloadIcon} width={28} height={27} alt="Download" />

            <span>{item.app_file_name}</span>
          </a>
        ))}
      </Styled.FileSliceContainer>
    </SliceLayout>
  );
};

export default FileSlice;
