import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#a4a01a',
    secondary: '#731b1b',
    white: '#ffffff',
    black: '#000000',
    text: '#585045',
    highlight: 'rgba(164, 160, 26, 0.5)',
  },
  font: {
    family: "'Barlow', sans-serif",
    weights: {
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    sizes: {
      extraLarge: '24px',
      large: '20px',
      standard: '16px',
      medium: '15px',
      small: '14px',
      extraSmall: '13px',
    },
  },
};

export default theme;
