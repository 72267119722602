import React, { FC, useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { HttpError } from '@core/http';
import FullError from '@modules/error/components/FullError';
import { useCurrentLanguage } from '@shared/modules/translation/hooks';

const SentryProvider: FC = ({ children }) => {
  const language = useCurrentLanguage();

  useEffect(() => {
    Sentry.configureScope(scope => {
      scope.setExtra('lang', language);
    });
  }, [language]);

  return <Sentry.ErrorBoundary fallback={<FullError error={HttpError.default} />}>{children}</Sentry.ErrorBoundary>;
};

export default SentryProvider;
