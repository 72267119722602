import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

export function filterEmptyStringToOption(value: string | null | undefined): O.Option<string> {
  return pipe(
    O.fromNullable(value),
    O.filter(v => v !== ''),
  );
}

export function filterEmptyStringToNullable(value: string | null | undefined): string | null {
  return O.toNullable(filterEmptyStringToOption(value));
}
