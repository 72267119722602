import React, { Dispatch, FC, SetStateAction, useMemo } from 'react';

import * as O from 'fp-ts/Option';
import { renderOptional } from '@shared/utils/render';
import Lightbox from 'react-image-lightbox';
import { PrismicGalleryItem } from '@modules/visits/model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import { filterPrismicElement } from '@core/prismic/utils';
import { buildImgixSrcAndSrcSets } from '@shared/utils/images';

interface FullGalleryProps {
  images: Array<PrismicGalleryItem>;
  index: O.Option<number>;
  setIndex: Dispatch<SetStateAction<O.Option<number>>>;
}

const FullGallery: FC<FullGalleryProps> = ({ images, index, setIndex }) => {
  const handleClose = () => setIndex(O.none);

  const imageSrc = useMemo(
    () =>
      pipe(
        images,
        A.filterMap(({ image }) => filterPrismicElement(image)),
        A.map(
          image =>
            buildImgixSrcAndSrcSets(image.url, {
              auto: 'compress,format',
              q: 75,
              w: 750,
              ar: '16:9',
              fit: 'crop',
              crop: 'edges',
            })[0],
        ),
      ),
    [images],
  );

  const getImage = (index: number) => pipe(imageSrc, A.lookup(index), O.toUndefined);

  const handleNext = () =>
    setIndex(old =>
      pipe(
        old,
        O.map(index => index + 1),
      ),
    );

  const handlePrev = () =>
    setIndex(old =>
      pipe(
        old,
        O.map(index => index - 1),
      ),
    );

  const getImageCaption = (index: number) =>
    pipe(
      images,
      A.lookup(index),
      O.chainNullableK(image => image.legend),
      O.toUndefined,
    );

  return renderOptional(index, index => (
    <Lightbox
      mainSrc={getImage(index) as any}
      nextSrc={getImage(index + 1)}
      prevSrc={getImage(index - 1)}
      imageCaption={getImageCaption(index)}
      onMoveNextRequest={handleNext}
      onMovePrevRequest={handlePrev}
      onCloseRequest={handleClose}
    />
  ));
};

export default FullGallery;
