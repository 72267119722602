import React, { FC } from 'react';
import { PrismicTagContent } from '@modules/visits/model';

import * as prismicH from '@prismicio/helpers';

import * as Styled from './TagDescription.styles';
import { PrismicDocument } from '@core/prismic';

import * as NEA from 'fp-ts/NonEmptyArray';
import { renderOptional } from '@shared/utils/render';
import { Link } from 'react-router-dom';

interface TagDescriptionProps {
  tag: PrismicTagContent;
  related: Array<PrismicDocument<PrismicTagContent>>;
}

const TagDescription: FC<TagDescriptionProps> = ({ tag, related }) => {
  return (
    <Styled.TagDescriptionContainer>
      <Styled.TagDescriptionHeader style={{ background: tag.color ?? undefined }}>
        <p>{prismicH.asText(tag.description_heading_1)}</p>
        <p>{prismicH.asText(tag.description_heading_2)}</p>
      </Styled.TagDescriptionHeader>

      <Styled.TagDescriptionContent>
        <Styled.TagDescriptionText field={tag.description_text} />

        {renderOptional(NEA.fromArray(related), related => (
          <Styled.TagDescriptionRelated>
            {related.map((related, i) => (
              <li key={i}>
                <Link to={`../tags/${related.uid}`}>{prismicH.asText(related.data.title)}</Link>
              </li>
            ))}
          </Styled.TagDescriptionRelated>
        ))}
      </Styled.TagDescriptionContent>
    </Styled.TagDescriptionContainer>
  );
};

export default TagDescription;
