import React, { FC } from 'react';
import { PrismicTagContent } from '@modules/visits/model';
import { renderNullable } from '@shared/utils/render';

import * as Styled from './TagHeader.styles';

import * as prismicH from '@prismicio/helpers';

interface TagHeaderProps {
  tag: PrismicTagContent;
}

const TagHeader: FC<TagHeaderProps> = ({ tag }) => {
  return renderNullable(
    tag.audio_player,
    player => (
      <Styled.TagHeaderPlayerContainer>
        <Styled.TagHeaderImage
          sizes={{ default: 'calc(100vw - 70px)' }}
          image={tag.image}
          imgixParams={{ ar: '4:3', fit: 'crop', crop: 'edges' }}
        />

        <Styled.TagHeaderPlayer dangerouslySetInnerHTML={{ __html: player }} />
      </Styled.TagHeaderPlayerContainer>
    ),
    () => (
      <>
        <Styled.TagHeaderTitle>{prismicH.asText(tag.title)}</Styled.TagHeaderTitle>

        <Styled.TagHeaderImage
          sizes={{ default: 'calc(100vw - 40px)' }}
          image={tag.image}
          imgixParams={{ ar: '4:3', fit: 'crop', crop: 'edges' }}
        />
      </>
    ),
  );
};

export default TagHeader;
