import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { NavItemType } from '@layout/nav/Nav';
import { colors } from '@styles/utils';

import listIcon from '@assets/icons/nav/list.svg';
import scanIcon from '@assets/icons/nav/scan.png';
import mapIcon from '@assets/icons/nav/map.png';

export const NavContainer = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 90px;
  width: 100%;
  background: ${colors.white};
  z-index: 100;
  box-shadow: 5px 5px 14px 5px rgba(0, 0, 0, 0.1);
`;

export const NavListContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
`;

const CommonNavItem = styled(NavLink)`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;

  &.active::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 19px;
    left: 50%;
    width: 74px;
    height: 3px;
    background: ${colors.primary};
    transform: translateX(-50%);
  }
`;

function getItemStyle(type: NavItemType) {
  switch (type) {
    case 'list':
      return css`
        background: url(${listIcon}) no-repeat top 28px center ${colors.white};
      `;
    case 'scan':
      return css`
        background: url(${scanIcon}) no-repeat top 22px center ${colors.primary};
        background-size: 47px 47px;

        &.active::after {
          display: none;
        }
      `;
    case 'map':
      return css`
        background: url(${mapIcon}) no-repeat top 25px center ${colors.white};
        background-size: 52px 34px;
      `;
  }
}

export const NavItem = styled(CommonNavItem)<{ $type: NavItemType }>`
  ${props => getItemStyle(props.$type)};
`;
