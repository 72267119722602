import React, { FC } from 'react';
import { PrismicTagContent } from '@modules/visits/model';

import * as Styled from './TagCard.styles';
import { PrismicDocument } from '@core/prismic';

import * as prismicH from '@prismicio/helpers';
import Image from '@core/prismic/components/Image';
import { renderOptional } from '@shared/utils/render';
import * as NEA from 'fp-ts/NonEmptyArray';

interface TagCardProps {
  tag: PrismicDocument<PrismicTagContent>;
}

const TagCard: FC<TagCardProps> = ({ tag }) => {
  const { data } = tag;

  return (
    <Styled.TagCardContainer to={`../tags/${tag.uid}`}>
      <Styled.TagCardHeader style={{ background: data.color ?? undefined }}>
        {prismicH.asText(data.title)}
      </Styled.TagCardHeader>

      <Styled.TagCardImageWrapper>
        <Image
          image={data.image}
          sizes={{ default: 'calc(100vw - 40px)' }}
          imgixParams={{ ar: '16:9', fit: 'crop', crop: 'edges' }}
        />

        {renderOptional(NEA.fromArray(data.icons.map(icon => icon.icon)), icons => (
          <Styled.TagCardIconsWrapper>
            {icons.map((icon, i) => (
              <Image
                key={i}
                image={icon}
                width={38}
                height={38}
                imgixParams={{ fit: 'fill', fill: 'solid', fm: 'png' }}
              />
            ))}
          </Styled.TagCardIconsWrapper>
        ))}
      </Styled.TagCardImageWrapper>
    </Styled.TagCardContainer>
  );
};

export default TagCard;
