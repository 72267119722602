import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import ReactModal from 'react-modal';

import 'react-image-lightbox/style.css';
import '@innovantic/cookie-consent/dist/style.css';

import '@shared/modules/translation';

import { configureSentry } from '@shared/modules/sentry/utils';

ReactModal.setAppElement('#root');

configureSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
