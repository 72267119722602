import { CookieConsentText } from '@innovantic/cookie-consent';

export const cookieConsentEnglishText: Partial<CookieConsentText> = {
  bannerLine1: 'We use cookies to analyze traffic on our site and improve your experience.',
  bannerLine2: 'By accepting, you help us to improve.',
  bannerAccept: 'Accept all',
  bannerDecline: 'Decline all',
  modalTitle: 'Manage cookies',
  modalDescription:
    'When you visit websites, some of them may deposit or collect data on your browser. This data collection is often necessary for the operation of certain sites, but it can also be used for marketing, analytics, and personalization purposes, such as recording your preferences. Your privacy is important to us and we allow you to opt out of certain types of data collection that are not strictly necessary for the operation of the site.',
  modalStatisticTitle: 'Statistics and optimization',
  modalStatisticDescription: 'To provide you the best experience, our site uses statistical cookies.',
  modalAction: 'Submit',
};
