import styled from 'styled-components';
import { colors, fontSizes, fontWeights } from '@styles/utils';

export const FileSliceContainer = styled.div`
  a {
    display: grid;
    grid-template-columns: 28px 1fr;
    grid-gap: 10px;
    font-size: ${fontSizes.large};
    font-weight: ${fontWeights.medium};
    color: ${colors.black};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;
