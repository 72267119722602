import styled from 'styled-components';
import { colors, fontSizes, fontWeights, renderRatio } from '@styles/utils';
import Image from '@core/prismic/components/Image';

export const TagHeaderPlayerContainer = styled.div`
  padding: 15px;
  border: 1px solid ${colors.text};
`;

export const TagHeaderImage = styled(Image)`
  ${renderRatio(4 / 3)};
`;

export const TagHeaderPlayer = styled.div`
  padding-top: 10px;
`;

export const TagHeaderTitle = styled.h1`
  padding-bottom: 20px;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
  color: ${colors.text};
  text-align: center;
  text-transform: uppercase;
`;
