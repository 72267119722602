import styled from 'styled-components';
import { colors } from '@styles/utils';

export const FullErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  flex: 1 1 auto;
  height: 100%;
  text-align: center;
`;

export const FullErrorTitle = styled.h1`
  padding-bottom: 20px;
  font-size: 30px;
`;

export const FullErrorDescription = styled.p`
  color: ${colors.text};
`;
