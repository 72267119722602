import styled from 'styled-components';
import { RichText } from '@core/prismic/components';
import { colors, fontSizes, fontWeights } from '@styles/utils';
import Image from '@core/prismic/components/Image';

export const HomeHeaderTopContainer = styled.div`
  padding: 20px 20px 30px;
`;

export const HomeHeaderTopImageWrapper = styled.div`
  width: 105px;
  margin: 0 auto;
  padding-bottom: 30px;
`;

export const HomeHeaderTitle = styled(RichText)`
  max-width: 300px;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  font-size: ${fontSizes.extraLarge};
  color: #423b3b;

  strong {
    display: inline-block;
    margin-top: 5px;
    font-weight: ${fontWeights.medium};
  }
`;

export const HomeHeaderBottomContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  min-height: 600px;
  overflow: hidden;
  padding: 60px 0 20px;
`;

export const HomeHeaderMask = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: 40px;
  background: ${colors.white};
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  z-index: 1;
`;

export const HomeHeaderImage = styled(Image)`
  &,
  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const HomeHeaderBottomContent = styled.div`
  position: relative;
`;

export const HomeHeaderIntroContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
  margin: 0 40px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.6);

  > li {
    position: relative;
    padding-left: 35px;

    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      display: block;
      width: 17px;
      height: 17px;
      background: ${colors.primary};
      border-radius: 100%;
    }

    > p {
      font-weight: ${fontWeights.medium};
      padding-bottom: 5px;

      &:first-child {
        font-size: ${fontSizes.large};
        text-transform: uppercase;
      }

      &:last-child {
        font-size: ${fontSizes.medium};
      }
    }
  }
`;

export const HomeHeaderPlayer = styled.div`
  margin: 20px 10px 0;
`;
