import React, { FC } from 'react';

import * as Styled from './Nav.styles';
import { useParams } from 'react-router-dom';

export type NavItemType = 'list' | 'scan' | 'map';

interface NavItemProps {
  type: NavItemType;
}

export const NavItem: FC<NavItemProps> = ({ type }) => {
  const { id } = useParams();

  return (
    <li>
      <Styled.NavItem to={`/${id}/${type}`} $type={type}>
        <></>
      </Styled.NavItem>
    </li>
  );
};

const Nav: FC = () => (
  <Styled.NavContainer>
    <Styled.NavListContainer>
      <NavItem type="list" />
      <NavItem type="scan" />
      <NavItem type="map" />
    </Styled.NavListContainer>
  </Styled.NavContainer>
);

export default Nav;
