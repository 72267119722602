import React, { FC, useState } from 'react';
import { PrismicGallerySlice } from '@modules/visits/model';
import SliceLayout from '@modules/visits/components/tag/slice/common/SliceLayout';

import * as Styled from './GallerySlice.styles';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { filterPrismicElement } from '@core/prismic/utils';
import { renderOptional } from '@shared/utils/render';
import Lightbox from 'react-image-lightbox';

import * as prismicT from '@prismicio/types';
import { buildImgixSrcAndSrcSets } from '@shared/utils/images';

function getLightBoxImage(image: prismicT.FilledImageFieldImage) {
  return buildImgixSrcAndSrcSets(image.url, {
    ar: '3:2',
    fit: 'crop',
    crop: 'edges',
    auto: 'compress,format',
    w: 750,
  })[0];
}

interface GallerySliceProps {
  slice: PrismicGallerySlice;
}

const GallerySlice: FC<GallerySliceProps> = ({ slice }) => {
  const [galleryIndex, setGalleryIndex] = useState<O.Option<number>>(O.none);

  const images = pipe(
    slice.items,
    A.filterMap(image => filterPrismicElement(image.app_gallery_image)),
  );

  const handleOpenLightBox = (index: number) => () => setGalleryIndex(O.some(index));

  const handleCloseLightBox = () => setGalleryIndex(O.none);

  const getGalleryImage = (index: number) => pipe(images, A.lookup(index), O.map(getLightBoxImage), O.toUndefined);

  const handleMoveNext = () =>
    setGalleryIndex(old =>
      pipe(
        old,
        O.map(i => i + 1),
      ),
    );

  const handleMovePrev = () =>
    setGalleryIndex(old =>
      pipe(
        old,
        O.map(i => i - 1),
      ),
    );

  return (
    <SliceLayout title={slice.primary.app_gallery_title}>
      <Styled.GallerySliceContainer>
        {images.map((image, i) => (
          <div key={i} onClick={handleOpenLightBox(i)}>
            <Styled.GallerySliceImage
              sizes={{ default: 'calc((100vw - 70px) / 2)' }}
              key={i}
              image={image}
              imgixParams={{ ar: '3:2', fit: 'crop', crop: 'edges' }}
            />
          </div>
        ))}
      </Styled.GallerySliceContainer>

      {renderOptional(galleryIndex, index => (
        <Lightbox
          mainSrc={getLightBoxImage(images[index])}
          prevSrc={getGalleryImage(index - 1)}
          nextSrc={getGalleryImage(index + 1)}
          onCloseRequest={handleCloseLightBox}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      ))}
    </SliceLayout>
  );
};

export default GallerySlice;
