import styled from 'styled-components';
import { colors, fontSizes, fontWeights } from '@styles/utils';

export const SliceLayoutContainer = styled.div`
  margin-top: 20px;
  padding: 15px;
  border: 1px solid ${colors.text};
`;

export const SliceLayoutTitle = styled.p`
  text-align: center;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
  color: ${colors.text};
  text-transform: uppercase;
  padding-bottom: 20px;
`;
