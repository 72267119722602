import React, { CSSProperties } from 'react';

import { PrismicRichText } from '@prismicio/react';
import { RichTextContainer } from '@styles/shared';
import * as prismicT from '@prismicio/types';

interface RichTextProps {
  field: prismicT.RichTextField;
  style?: CSSProperties;
  className?: string;
}

export const RichText = ({ field, style, className }: RichTextProps) => {
  return (
    <RichTextContainer style={style} className={className}>
      <PrismicRichText field={field} />
    </RichTextContainer>
  );
};
