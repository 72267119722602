import * as Prismic from '@prismicio/client';

import { Lazy, pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { HttpError, HttpTask } from '@core/http';
import { PrismicContent, PrismicDocument, PrismicPageType } from '@core/prismic/model';

import * as Sentry from '@sentry/react';
import { Language } from '@shared/modules/translation';

const client = Prismic.createClient(Prismic.getRepositoryEndpoint('vegetag'));

function transformPrismicRequest<T>(request: Lazy<Promise<T>>): HttpTask<T> {
  return TE.tryCatch(request, err => {
    console.error(err);

    if (err instanceof Prismic.PrismicError && !err.response) {
      return HttpError.notFound;
    }

    Sentry.captureException(err);

    return HttpError.default;
  });
}

function getPrismicLocale(locale?: string): string {
  return locale === 'en' ? 'en-gb' : 'fr-fr';
}

export function getPrismicDocumentByUID<R extends PrismicContent>(
  type: PrismicPageType,
  uid: string,
  locale: Language,
): HttpTask<PrismicDocument<R>> {
  const getByLang = (lang: string) =>
    transformPrismicRequest(() => client.getByUID<PrismicDocument<R>>(type, uid, { lang }));

  return pipe(
    getByLang(getPrismicLocale(locale)),
    TE.orElse(() => getByLang('*')),
  );
}

export function getAll<R extends PrismicContent>(
  locale: string,
  params?: Parameters<Prismic.Client['dangerouslyGetAll']>[0],
): HttpTask<Array<PrismicDocument<R>>> {
  return transformPrismicRequest(() =>
    client.dangerouslyGetAll<PrismicDocument<R>>({ ...params, lang: getPrismicLocale(locale) }),
  );
}
