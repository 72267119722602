import * as O from 'fp-ts/Option';

import { pipe } from 'fp-ts/function';
import { filterEmptyStringToOption } from '@shared/utils/string';

const buildEnv = import.meta.env;

export function getConfig(): ImportMetaEnv {
  return {
    ...buildEnv,
    ...window._env_,
  };
}

export const getConfigValue = (key: string): O.Option<string> =>
  pipe(
    O.fromNullable(getConfig()[key]),
    O.filterMap(val => (typeof val === 'string' ? filterEmptyStringToOption(val) : O.none)),
  );
