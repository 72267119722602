import React, { FC } from 'react';
import { PrismicVisitContent } from '@modules/visits/model';

import * as prismicH from '@prismicio/helpers';

import * as Styled from './HomeSteps.styles';
import HomeStepItem from '@modules/visits/components/home/steps/item/HomeStepItem';

interface HomeStepsProps {
  visit: PrismicVisitContent;
}

const HomeSteps: FC<HomeStepsProps> = ({ visit }) => {
  return (
    <Styled.HomeStepsContainer>
      <Styled.HomeStepsTitle>{prismicH.asText(visit.steps_title)}</Styled.HomeStepsTitle>

      <Styled.HomeStepsList>
        {visit.steps?.map((item, index) => (
          <HomeStepItem key={index} item={item} index={index} />
        ))}
      </Styled.HomeStepsList>
    </Styled.HomeStepsContainer>
  );
};

export default HomeSteps;
