import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import { getConfigValue } from '@shared/utils/config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HttpError } from '@core/http';

export function configureSentry() {
  if (import.meta.env.PROD) {
    pipe(
      sequenceS(O.Apply)({
        dsn: getConfigValue('VITE_SENTRY_DSN'),
        environment: getConfigValue('VITE_ENVIRONMENT'),
        release: getConfigValue('VITE_RELEASE'),
      }),
      O.fold(
        () => console.warn('Missing sentry dns, environment or release'),
        options => {
          Sentry.init({
            ...options,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
            attachStacktrace: true,
          });
        },
      ),
    );
  }
}

export function logSentryMessage(
  message: string,
  severity: Sentry.Severity = Sentry.Severity.Error,
  extras?: { [key: string]: any },
) {
  Sentry.captureMessage(message, {
    level: severity,
    extra: extras,
  });
}

export function logSentryHttpError(
  message: string,
  error: HttpError,
  severity: Sentry.Severity = Sentry.Severity.Error,
  extras?: { [key: string]: any },
) {
  logSentryMessage(message, severity, {
    ...error.toJson(),
    ...extras,
  });
}
