import React, { FC } from 'react';
import { LinkProps } from '@prismicio/react';
import { Link } from 'react-router-dom';

const PrismicInternalLink: FC<LinkProps> = ({ href, target, rel, children }) => {
  if (href.trim().startsWith('http')) {
    return (
      <a href={href} target={target} rel={rel}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={href} target={target} rel={rel}>
        {children}
      </Link>
    );
  }
};

export default PrismicInternalLink;
