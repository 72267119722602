import React, { FC, useState } from 'react';
import { SrcSetOptions } from '@imgix/js-core';
import generateSizes, { SizesParams } from 'html-sizes';

import * as PrismicT from '@prismicio/types';

import * as Styled from './Image.styles';
import { buildImgixSrcAndSrcSets } from '@shared/utils/images';
import { renderPrismicElement } from '@core/prismic/utils';

const MAX_SRC_SET_WIDTH = 1500;

const DEFAULT_IMGIX_PARAMS = {
  auto: 'compress,format',
  q: 60,
};

function getSrcSetOptions(image: PrismicT.ImageField, srcSetOptions: SrcSetOptions = {}): SrcSetOptions {
  const minWidth = Math.max(srcSetOptions.minWidth ?? 0, 100);

  return {
    minWidth,
    maxWidth: Math.max(
      200,
      minWidth,
      Math.min(image.dimensions?.width ?? MAX_SRC_SET_WIDTH, srcSetOptions.maxWidth ?? MAX_SRC_SET_WIDTH),
    ),
    devicePixelRatios: [1, 2],
    ...srcSetOptions,
  };
}

interface ImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src' | 'sizes' | 'placeholder' | 'resource' | 'srcSet'> {
  image?: PrismicT.FilledImageFieldImage | null;
  sizes?: SizesParams;
  lazy?: boolean;
  imgixParams?: {};
  intrinsic?: boolean;
  srcSetOptions?: SrcSetOptions;
}

const Image: FC<ImageProps> = ({
  image,
  sizes: receivedSizes,
  width,
  height,
  alt,
  lazy,
  imgixParams,
  className,
  srcSetOptions,
  intrinsic,
  ...rest
}) => {
  const [sizes] = useState(() => (receivedSizes ? generateSizes(receivedSizes, { maxDPR: 2 }) : undefined));

  return renderPrismicElement(image, image => {
    const [src, srcSet] = buildImgixSrcAndSrcSets(
      image.url,
      { ...DEFAULT_IMGIX_PARAMS, ...imgixParams, w: width, h: height },
      getSrcSetOptions(image, srcSetOptions),
    );

    const attributes = lazy
      ? {
          'data-sizes': sizes,
          'data-src': src,
          'data-srcset': srcSet,
        }
      : {
          sizes: sizes,
          src: src,
          srcSet: srcSet,
        };

    return (
      <Styled.ImageContainer className={className}>
        <img
          {...attributes}
          className={lazy ? 'lazyload' : undefined}
          width={width ?? (intrinsic ? image.dimensions.width : undefined)}
          height={height ?? (intrinsic ? image.dimensions.height : undefined)}
          alt={alt ?? image.alt ?? undefined}
          {...rest}
        />
      </Styled.ImageContainer>
    );
  });
};

export default Image;
