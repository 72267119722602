import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { renderHttpRemoteData } from '@shared/utils/render';

import * as O from 'fp-ts/Option';

import * as Styled from './Gallery.styles';
import Image from '@core/prismic/components/Image';
import FullGallery from '@modules/visits/components/gallery/full/FullGallery';
import { renderPrismicElement } from '@core/prismic/utils';

import * as prismicH from '@prismicio/helpers';
import { useGallery } from '@modules/visits/context';

const Gallery: FC = () => {
  const { galleryId } = useParams<'galleryId'>() as { galleryId: string };

  const gallery = useGallery(galleryId);

  const [galleryIndex, setGalleryIndex] = useState<O.Option<number>>(() => O.none);

  const handleSelectImage = (index: number) => () => setGalleryIndex(O.some(index));

  return renderHttpRemoteData(gallery, gallery => (
    <Styled.GalleryContainer>
      {renderPrismicElement(gallery.data.title, title => (
        <Styled.GalleryTitle>{prismicH.asText(title)}</Styled.GalleryTitle>
      ))}

      <Styled.GalleryContent>
        {gallery.data.images.map(({ image, legend }, i) => (
          <Styled.GalleryImageContainer key={i} onClick={handleSelectImage(i)}>
            <Image
              sizes={{ default: '100vw' }}
              image={image}
              imgixParams={{ ar: '16:9', fit: 'crop', crop: 'edges' }}
            />

            {legend ? <Styled.GalleryImageLegend>{legend}</Styled.GalleryImageLegend> : null}
          </Styled.GalleryImageContainer>
        ))}
      </Styled.GalleryContent>

      <FullGallery images={gallery.data.images} index={galleryIndex} setIndex={setGalleryIndex} />
    </Styled.GalleryContainer>
  ));
};

export default Gallery;
