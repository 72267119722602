import styled from 'styled-components';
import { colors, fontSizes, fontWeights } from '@styles/utils';

export const HomeStepsContainer = styled.div`
  padding: 20px;
  text-align: center;
`;

export const HomeStepsTitle = styled.h2`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  color: ${colors.text};
`;

export const HomeStepsList = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  padding: 40px 0 25px;
  justify-content: center;
`;
