import { FC, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '@styles/theme';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import VisitsRoutes from '@modules/visits/routes';
import { GlobalStyles } from '@styles/global';
import { PrismicProvider, PrismicToolbar } from '@prismicio/react';
import { defaultLinkResolver } from '@core/prismic/resolver';
import PrismicInternalLink from '@core/prismic/components/PrismicInternalLink';
import ResolverRoutes from '@modules/resolver/routes';
import { Routes } from '@core/router';
import CookieConsent from '@innovantic/cookie-consent';
import AnalyticsTracker from '@shared/modules/analytics/components/AnalyticsTracker';
import { cookieConsentEnglishText } from '@shared/modules/analytics/model';
import SentryProvider from '@shared/modules/sentry/components/SentryProvider';
import { useCurrentLanguage } from '@shared/modules/translation/hooks';

const ScrollRestoration: FC = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
};

const App: FC = () => {
  const language = useCurrentLanguage();

  return (
    <SentryProvider>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />

          <Helmet>
            <html lang={language} />
          </Helmet>

          <CookieConsent hideCustomizeButton text={language === 'en' ? cookieConsentEnglishText : undefined}>
            <BrowserRouter>
              <AnalyticsTracker />
              <PrismicProvider linkResolver={defaultLinkResolver} internalLinkComponent={PrismicInternalLink}>
                <ScrollRestoration>
                  <Routes>
                    <Route path=":id/*" element={<VisitsRoutes />} />
                    <Route path="resolver/*" element={<ResolverRoutes />} />
                  </Routes>
                </ScrollRestoration>
              </PrismicProvider>
            </BrowserRouter>
          </CookieConsent>

          <PrismicToolbar repositoryName="vegetag" />
        </ThemeProvider>
      </HelmetProvider>
    </SentryProvider>
  );
};

export default App;
