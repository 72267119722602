import React, { FC } from 'react';

import { Helmet } from 'react-helmet-async';
import { renderNullable } from '@shared/utils/render';

export interface SeoProps {
  title?: string | null;
}

const Seo: FC<SeoProps> = ({ title }) => {
  return (
    <Helmet>
      {renderNullable(title, title => (
        <title>{title} - Végétag</title>
      ))}
    </Helmet>
  );
};

export default Seo;
