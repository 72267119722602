import styled from 'styled-components';
import { fontWeights } from '@styles/utils';

export const RichTextContainer = styled.div`
  p {
    min-height: 5px;
    margin-bottom: 10px;
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  em {
    font-style: italic;
  }

  ul {
    list-style: disc;
    padding-left: 25px;
    margin-bottom: 10px;
  }

  ol {
    list-style: decimal;
    padding-left: 25px;
    margin-bottom: 10px;
  }
`;
