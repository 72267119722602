import { ReactNode } from 'react';
import { constNull, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as RD from 'fp-ts-remote-data';
import { HttpError, HttpRemoteData } from '@core/http';
import FullError from '@modules/error/components/FullError';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';

export function renderOptional<T, R extends ReactNode>(
  opt: O.Option<T>,
  onSome: (data: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return pipe(opt, O.fold(onNone, onSome));
}

export function renderNullable<T, R extends ReactNode>(
  value: T | null | undefined,
  onSome: (data: T) => R,
  onNone: () => R | null = constNull,
): R | null {
  return renderOptional(O.fromNullable(value), onSome, onNone);
}

export function renderHttpRemoteData<E, A, R extends ReactNode>(
  value: HttpRemoteData<A, E>,
  onSuccess: (data: A) => R,
  onPending: () => R | JSX.Element = () => <DebouncedLineLoader />,
  onFailure: (error: HttpError<E>) => R | JSX.Element = error => <FullError error={error} />,
): R | JSX.Element {
  return pipe(value, RD.fold(onPending, onSuccess, onFailure));
}
