import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as RD from 'fp-ts-remote-data';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as M from 'fp-ts/Map';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as String from 'fp-ts/string';
import { HttpError, HttpRemoteData, HttpTask } from '@core/http';
import { PrismicGalleryContent, PrismicTagContent, PrismicVisitContent } from '@modules/visits/model';
import { constVoid, pipe } from 'fp-ts/function';

import * as Prismic from '@prismicio/client';

import { getAll, getPrismicDocumentByUID, PrismicContent, PrismicDocument } from '@core/prismic';
import { renderHttpRemoteData } from '@shared/utils/render';
import Seo from '@shared/components/seo/Seo';
import { sequenceS } from 'fp-ts/Apply';
import { Language } from '@shared/modules/translation';
import { useCurrentLanguage } from '@shared/modules/translation/hooks';

interface VisitContextValue {
  visit: PrismicDocument<PrismicVisitContent>;
  tags: Map<string, PrismicDocument<PrismicTagContent>>;
  galleries: Map<string, PrismicDocument<PrismicGalleryContent>>;
  setTags: Dispatch<SetStateAction<Map<string, PrismicDocument<PrismicTagContent>>>>;
  setGalleries: Dispatch<SetStateAction<Map<string, PrismicDocument<PrismicGalleryContent>>>>;
}

const VisitContext = createContext<VisitContextValue>({
  visit: {} as any,
  tags: new Map<string, PrismicDocument<PrismicTagContent>>(),
  galleries: new Map<string, PrismicDocument<PrismicGalleryContent>>(),
  setTags: constVoid,
  setGalleries: constVoid,
});

function getAllToMap<R extends PrismicContent>(
  task: HttpTask<Array<PrismicDocument<R>>>,
): HttpTask<Map<string, PrismicDocument<R>>> {
  return pipe(
    task,
    TE.map(documents => new Map<string, PrismicDocument<R>>(documents.map(document => [document.id, document]))),
  );
}

function fetchVisit(id: string, lang: Language) {
  return pipe(
    TE.Do,
    TE.bind('visit', () => getPrismicDocumentByUID<PrismicVisitContent>('visit_app', id, lang)),
    TE.bind('related', ({ visit }) =>
      pipe(
        sequenceS(TE.ApplyPar)({
          tags: getAllToMap(
            getAll<PrismicTagContent>(lang, {
              predicates: [
                Prismic.predicate.at('document.type', 'tag_app'),
                Prismic.predicate.at('my.tag_app.visit', visit.id),
              ],
            }),
          ),
          galleries: getAllToMap(
            getAll<PrismicGalleryContent>(lang, {
              predicates: [
                Prismic.predicate.at('document.type', 'gallery_app'),
                Prismic.predicate.at('my.gallery_app.visit', visit.id),
              ],
            }),
          ),
        }),
      ),
    ),
  );
}

const VisitContextProvider: FC = ({ children }) => {
  const { id } = useParams<'id'>() as { id: string };

  const language = useCurrentLanguage();

  const [visit, setVisit] = useState<HttpRemoteData<PrismicDocument<PrismicVisitContent>>>(() => RD.pending);

  const [tags, setTags] = useState<Map<string, PrismicDocument<PrismicTagContent>>>(
    () => new Map<string, PrismicDocument<PrismicTagContent>>(),
  );

  const [galleries, setGalleries] = useState<Map<string, PrismicDocument<PrismicGalleryContent>>>(
    () => new Map<string, PrismicDocument<PrismicGalleryContent>>(),
  );

  useEffect(() => {
    pipe(
      fetchVisit(id, language),
      TE.fold(
        err => T.fromIO(() => setVisit(RD.failure(err))),
        res =>
          T.fromIO(() => {
            setVisit(RD.success(res.visit));
            setTags(res.related.tags);
            setGalleries(res.related.galleries);
          }),
      ),
    )();
  }, [language, id]);

  return renderHttpRemoteData(visit, visit => (
    <VisitContext.Provider value={{ visit, tags, galleries, setTags, setGalleries }}>
      <Seo title={visit.data.name} />
      {children}
    </VisitContext.Provider>
  ));
};

export function useVisitContext() {
  return useContext(VisitContext);
}

export function useGallery(id: string) {
  const { galleries } = useVisitContext();

  return useMemo(
    () =>
      pipe(
        Array.from(galleries.values()),
        A.findFirst(gallery => gallery.uid === id),
        RD.fromOption(() => HttpError.notFound),
      ),
    [galleries, id],
  );
}

export function useTag(id: string) {
  const { tags } = useVisitContext();

  return useMemo(
    () =>
      pipe(
        O.Do,
        O.bind('tag', () =>
          pipe(
            Array.from(tags.values()),
            A.findFirst(tag => tag.uid === id),
          ),
        ),
        O.bind('related', ({ tag }) =>
          pipe(
            tag.data.related_tags.map(tag => tag.tag),
            A.filterMap(O.fromNullable),
            A.filterMap(tag => M.lookup(String.Eq)(tag.id)(tags)),
            O.some,
          ),
        ),
        RD.fromOption(() => HttpError.notFound),
      ),
    [id, tags],
  );
}

export default VisitContextProvider;
