import React, { FC, useMemo } from 'react';
import { useVisitContext } from '@modules/visits/context';

import * as Styled from './TagsList.styles';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as M from 'fp-ts/Map';
import * as String from 'fp-ts/string';
import TagCard from '@modules/visits/components/list/card/TagCard';

const TagsList: FC = () => {
  const { visit, tags } = useVisitContext();

  const list = useMemo(
    () =>
      pipe(
        visit.data.tags.map(tag => tag.tag),
        A.filterMap(tag => M.lookup(String.Eq)(tag.id)(tags)),
      ),
    [tags, visit.data.tags],
  );

  return (
    <Styled.TagsListContainer>
      {list.map((tag, i) => (
        <TagCard key={i} tag={tag} />
      ))}
    </Styled.TagsListContainer>
  );
};

export default TagsList;
