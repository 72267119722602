import styled from 'styled-components';
import { colors, fontSizes, fontWeights } from '@styles/utils';
import { RichText } from '@core/prismic/components';

export const HomeStepItemContainer = styled.li``;

export const HomeStepItemNumber = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 42px;
  height: 42px;
  background: ${colors.primary};
  border-radius: 100%;
  font-size: 21px;
  font-weight: ${fontWeights.medium};
  color: ${colors.white};
`;

export const HomeStepItemText = styled(RichText)`
  margin: 10px auto 0;
  max-width: 150px;
  font-size: ${fontSizes.extraSmall};
  font-weight: ${fontWeights.medium};
  color: ${colors.text};
  text-transform: uppercase;
`;
