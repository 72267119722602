import React, { FC, useEffect } from 'react';
import { Route, useNavigate, useParams } from 'react-router-dom';
import { HttpError } from '@core/http';
import { DebouncedLineLoader } from '@layout/loaders/line-loader/LineLoader';
import { pipe } from 'fp-ts/function';
import { getPrismicDocumentByUID } from '@core/prismic';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';

import * as TE from 'fp-ts/TaskEither';
import { PrismicGalleryContent } from '@modules/visits/model';
import { Routes } from '@core/router';
import { useCurrentLanguage } from '@shared/modules/translation/hooks';

const TagResolver: FC = () => {
  const { id } = useParams<'id'>() as { id: string };

  const navigate = useNavigate();

  const language = useCurrentLanguage();

  useEffect(() => {
    pipe(
      getPrismicDocumentByUID<PrismicGalleryContent>('tag_app', id, language),
      TE.chainOptionK(() => HttpError.notFound)(document => O.fromNullable(document.data.visit?.uid)),
      T.chainIOK(res => () => {
        const link = pipe(
          res,
          EI.fold(
            () => '/',
            visit => `/${visit}/tags/${id}`,
          ),
        );

        navigate(link);
      }),
    )();
  }, [language, id, navigate]);

  return <DebouncedLineLoader />;
};

const GalleryResolver: FC = () => {
  const { id } = useParams<'id'>() as { id: string };

  const navigate = useNavigate();

  const language = useCurrentLanguage();

  useEffect(() => {
    pipe(
      getPrismicDocumentByUID<PrismicGalleryContent>('gallery_app', id, language),
      TE.chainOptionK(() => HttpError.notFound)(document => O.fromNullable(document.data.visit?.uid)),
      T.chainIOK(res => () => {
        const link = pipe(
          res,
          EI.fold(
            () => '/',
            visit => `/${visit}/galleries/${id}`,
          ),
        );

        navigate(link);
      }),
    )();
  }, [language, id, navigate]);

  return <DebouncedLineLoader />;
};

const ResolverRoutes: FC = () => (
  <Routes>
    <Route path="tags/:id" element={<TagResolver />} />
    <Route path="galleries/:id" element={<GalleryResolver />} />
  </Routes>
);

export default ResolverRoutes;
