import styled from 'styled-components';
import { colors, fontSizes, fontWeights } from '@styles/utils';
import { RichText } from '@core/prismic/components';

export const TagDescriptionContainer = styled.div`
  margin-top: 20px;
`;

export const TagDescriptionHeader = styled.div`
  padding: 10px 20px;
  background: ${colors.primary};
  color: ${colors.white};
  overflow: hidden;

  > p {
    &:first-child {
      padding-bottom: 5px;
      font-size: ${fontSizes.medium};
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:last-child {
      font-size: 17px;
      font-weight: ${fontWeights.bold};
    }
  }
`;

export const TagDescriptionContent = styled.div`
  padding: 20px;
  border: 1px solid ${colors.text};
  border-top: none;
`;

export const TagDescriptionText = styled(RichText)`
  font-size: 18px;
`;

export const TagDescriptionRelated = styled.ul`
  margin-top: 20px;
  display: grid;
  grid-template: auto / 1fr;
  grid-row-gap: 10px;

  li {
    overflow: hidden;

    a {
      display: block;
      padding: 10px;
      border: 1px solid ${colors.primary};
      border-radius: 5px;
      font-size: 18px;
      font-weight: ${fontWeights.semiBold};
      color: ${colors.primary};
      text-decoration: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
