import { PrismicAppSlice } from '@modules/visits/model';
import { FC } from 'react';
import GallerySlice from '@modules/visits/components/tag/slice/gallery/GallerySlice';
import VideoSlice from '@modules/visits/components/tag/slice/video/VideoSlice';
import FileSlice from '@modules/visits/components/tag/slice/file/FileSlice';

interface TagSliceProps {
  slice: PrismicAppSlice;
}

const TagSlice: FC<TagSliceProps> = ({ slice }) => {
  switch (slice.slice_type) {
    case 'app_gallery':
      return <GallerySlice slice={slice} />;
    case 'app_video':
      return <VideoSlice slice={slice} />;
    case 'app_file':
      return <FileSlice slice={slice} />;
    default:
      return null;
  }
};

export default TagSlice;
