import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import Home from '@modules/visits/components/home/Home';

import { Routes, safeLazy } from '@core/router';
import VisitContextProvider from '@modules/visits/context';
import TagsList from '@modules/visits/components/list/TagsList';
import Gallery from '@modules/visits/components/gallery/Gallery';
import Tag from '@modules/visits/components/tag/Tag';

const Layout = safeLazy(() => import('@layout/Layout'));
const QrScanner = safeLazy(() => import('@modules/visits/components/qr-scanner/QrScanner'));
const Map = safeLazy(() => import('@modules/visits/components/map/Map'));

const VisitsRoutes: FC = () => (
  <VisitContextProvider>
    <Routes>
      <Route index element={<Home />} />
      <Route element={<Layout />}>
        <Route path="list" element={<TagsList />} />
        <Route path="scan" element={<QrScanner />} />
        <Route path="map" element={<Map />} />

        <Route path="tags/:tagId" element={<Tag />} />
        <Route path="galleries/:galleryId" element={<Gallery />} />
      </Route>
    </Routes>
  </VisitContextProvider>
);

export default VisitsRoutes;
