import React, { FC } from 'react';
import HomeHeader from '@modules/visits/components/home/header/HomeHeader';

import * as Styled from './Home.styles';
import HomeSteps from '@modules/visits/components/home/steps/HomeSteps';
import { useVisitContext } from '@modules/visits/context';
import Nav from '@layout/nav/Nav';

const Home: FC = () => {
  const { visit } = useVisitContext();

  return (
    <Styled.HomeContainer>
      <HomeHeader visit={visit.data} />
      <HomeSteps visit={visit.data} />

      <Nav />
    </Styled.HomeContainer>
  );
};

export default Home;
