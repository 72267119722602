import React, { FC } from 'react';
import { PrismicVideoSlice } from '@modules/visits/model';
import SliceLayout from '@modules/visits/components/tag/slice/common/SliceLayout';
import { renderNullable } from '@shared/utils/render';

import * as Styled from './VideoSlice.styles';

interface VideoSliceProps {
  slice: PrismicVideoSlice;
}

const VideoSlice: FC<VideoSliceProps> = ({ slice }) => {
  return (
    <SliceLayout title={slice.primary.app_video_title}>
      {renderNullable(slice.primary.app_video?.html, html => (
        <Styled.VideoSliceContainer dangerouslySetInnerHTML={{ __html: html }} />
      ))}
    </SliceLayout>
  );
};

export default VideoSlice;
