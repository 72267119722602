import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, renderRatio } from '@styles/utils';

export const TagCardContainer = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  overflow: hidden;
`;

export const TagCardHeader = styled.div`
  padding: 5px 8px;
  background: ${colors.primary};
  font-size: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TagCardImageWrapper = styled.div`
  position: relative;
  padding-top: 2px;

  > div:first-child {
    ${renderRatio(16 / 9)};
  }
`;

export const TagCardIconsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 38px);
  grid-gap: 10px;
  padding: 10px;
`;
