import React, { FC } from 'react';

import * as prismicT from '@prismicio/types';
import * as prismicH from '@prismicio/helpers';

import * as Styled from './SliceLayout.styles';

interface SliceLayoutProps {
  title: prismicT.TitleField;
}

const SliceLayout: FC<SliceLayoutProps> = ({ title, children }) => {
  return (
    <Styled.SliceLayoutContainer>
      <Styled.SliceLayoutTitle>{prismicH.asText(title)}</Styled.SliceLayoutTitle>
      {children}
    </Styled.SliceLayoutContainer>
  );
};

export default SliceLayout;
