import React, { FC } from 'react';

import * as Styled from './Tag.styles';
import { useParams } from 'react-router-dom';
import { renderHttpRemoteData } from '@shared/utils/render';
import TagHeader from '@modules/visits/components/tag/header/TagHeader';
import TagDescription from '@modules/visits/components/tag/description/TagDescription';
import TagSlice from './slice/TagSlice';
import { useTag } from '@modules/visits/context';

const Tag: FC = () => {
  const { tagId } = useParams<'tagId'>() as { tagId: string };

  const tag = useTag(tagId);

  return renderHttpRemoteData(tag, ({ tag: { data }, related }) => (
    <Styled.TagContainer>
      <TagHeader tag={data} />
      <TagDescription tag={data} related={related} />

      {data?.body.map((slice, i) => (
        <TagSlice key={i} slice={slice} />
      ))}
    </Styled.TagContainer>
  ));
};

export default Tag;
