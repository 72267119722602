import React, { FC } from 'react';
import { HomeStepItem as HomeStepItemType } from '@modules/visits/model';

import * as Styled from './HomeStepItem.styles';
import Image from '@core/prismic/components/Image';

interface HomeStepItemProps {
  item: HomeStepItemType;
  index: number;
}

const HomeStepItem: FC<HomeStepItemProps> = ({ item, index }) => {
  return (
    <Styled.HomeStepItemContainer>
      <Image sizes={{ default: 'calc(100vw - 40px)' }} image={item.step_image} intrinsic />

      <Styled.HomeStepItemNumber>{index + 1}</Styled.HomeStepItemNumber>

      <Styled.HomeStepItemText field={item.step_text} />
    </Styled.HomeStepItemContainer>
  );
};

export default HomeStepItem;
