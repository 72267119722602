import styled from 'styled-components';

export const VideoSliceContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;

  > iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`;
