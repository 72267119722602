import i18n from 'i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

import fr from './fr.json';
import en from './en.json';

const debug = process.env.NODE_ENV === 'development';

export const languages = ['fr', 'en'] as const;

export type Language = typeof languages[number];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: languages,
    fallbackLng: 'en',
    debug,
    detection: {
      order: ['localStorage', 'navigator'],
    },
    load: 'languageOnly',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
    },
  });

export default i18n;
